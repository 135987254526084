import { Icon, LoadingSpinner } from '@blueorigin/blue-branding-kit';
import * as _ from 'lodash';
import * as React from 'react';
import { NavigationState } from '../../redux/actions/navigation.actions';
import { PayloadsState } from '../../redux/actions/payloads.actions';

export interface PayloadLabelProps {
  navigationState: NavigationState;
  payloadsState: PayloadsState;
}

export class PayloadLabel extends React.Component<PayloadLabelProps> {
  public render() {
    const { payloadsState, navigationState } = this.props;

    const errorIcon = <Icon name="fas fa-exclamation-circle" />;

    // If insufficient information is available, show an error icon
    if (!payloadsState || !navigationState) {
      return errorIcon;
    }

    // Extract payload information
    const { payloads, loading, error, primary, initiated } = payloadsState;

    // If loading, show a loading spinner
    if (loading || !initiated) {
      return <LoadingSpinner />;
    }

    // If loading failed, show an error icon
    if (error) {
      return errorIcon;
    }

    if (payloads) {
      // Check if a payload is selected in navigation
      const selected = _.find(payloads, p => p.id === navigationState.payload);
      if (selected) {
        return selected.shortName || selected.name;
      } else if (primary) {
        // If there is a no payload selected but there is a primary payload, return that
        return primary.shortName || primary.name;
      }
    }

    // Happens if the response is valid but payloads is not truthy
    return errorIcon;
  }
}
