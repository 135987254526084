import { ApolloConsumer } from '@apollo/client';
import { Title } from '@blueorigin/blue-branding-kit';
import { PDPEmbed } from '@blueorigin/payload-components/components/pdp/pdp-embed';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { DefaultCustomerViewOptions } from '@blueorigin/payload-components/models/view';
import * as _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppBanner } from '../../layout/app-banner';
import { PDPSidebar } from './sidebar/pdp-sidebar';

export class ViewDataPackage extends React.Component<RouteComponentProps> {
  public render() {
    const id = _.get(this.props, ['match', 'params', 'payloadId']);
    return (
      <React.Fragment>
        <AppBanner>
          <Title>Data Package</Title>
        </AppBanner>
        <ApolloConsumer>
          {client => (
            <PDPEmbed
              id={id}
              client={client}
              sidebar={(payload: Payload | null, content: any) => (
                <PDPSidebar {...{ payload, content }} />
              )}
              viewOptions={DefaultCustomerViewOptions}
            />
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}
