import { Block, Blue, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import {
  Conversation,
  ConversationType,
  NewConversation,
} from '@blueorigin/conversation-components';
import { Dialog } from '@material-ui/core';
import * as React from 'react';

export interface SignoffNotReadyDialogProps {
  payloadId: string;
  visible: boolean;
  onClose: () => void;
}

export class SignoffNotReadyDialog extends React.Component<SignoffNotReadyDialogProps> {
  public render() {
    const { payloadId, onClose } = this.props;
    return (
      <Dialog
        open={this.props.visible}
        scroll="body"
        onClose={onClose}
        maxWidth="md"
        fullWidth={true}
      >
        <div>
          <Blue>
            <Block border={false}>
              <Segment>
                <Heading>Not ready to sign?</Heading>
              </Segment>
              <Segment>
                <Text>
                  If you have any questions or concerns before signing the data package, or if any
                  additional changes need to be made, prior to signing, you can use the below form
                  to contact us.
                </Text>
              </Segment>
            </Block>
            <NewConversation
              type={ConversationType.CONVERSATION}
              input={{ payloadId }}
              enableCategory={false}
              onAdd={() => {
                onClose();
                return true;
              }}
              fileId={`${payloadId}/temp`}
            />
          </Blue>
        </div>
      </Dialog>
    );
  }
}
