import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Error } from '@blueorigin/blue-branding-kit';
import { Block, Container, Link, Segment, theme, Title } from '@blueorigin/blue-branding-kit';
import { History } from 'history';
import * as _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { FilesLoading } from '../files/files-loading';
import { AppBanner } from '../layout/app-banner';
import { PrimaryFile } from './primary-file';
import { SecondaryFile } from './secondary-file';

export const FilesQuery = gql`
  query Q($payloadId: ID!) {
    sharedFiles(payloadId: $payloadId) {
      files {
        image
        url
        title
        subtitle
        description
        type
      }
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${theme('Responsive.DesktopOnlyMedia')} {
    > div {
      width: 50%;
      margin: 0;
    }
  }
`;

interface SharedFile {
  category: string;
  files: File[];
}

export interface File {
  image: any;
  url: string;
  title: string;
  subtitle?: string;
  description: string;
  type: string;
}

export interface FilesPageProps {
  history?: History;
  match?: any;
}

export class FilesPageComponent extends React.Component {
  public render() {
    const { payloadId } = _.get(this.props, ['match', 'params'], {});
    const renderFilesPage = (filesPageProps: any) => (
      <FilesPage {...filesPageProps} payloadId={payloadId} />
    );
    return (
      <Query query={FilesQuery} variables={{ payloadId }} fetchPolicy="no-cache">
        {(props: any) => renderFilesPage(props)}
      </Query>
    );
  }
}

const FilesPage = ({ data, error, loading, refetch, payloadId }: any) => {
  if (loading) {
    return <FilesLoading />;
  }
  if (error) {
    return (
      <Error data={error} tryAgain={refetch}>
        Could not load files
      </Error>
    );
  }

  const sharedFiles: SharedFile[] = data.sharedFiles;

  if (sharedFiles.length === 0 || !sharedFiles[0].files) {
    return (
      <>
        <AppBanner>
          <Title>Files</Title>
        </AppBanner>
        <Container>
          <Block extraPadding>
            <Error data={error}>No files are available at this time.</Error>
          </Block>
          <Block extraPadding border={false}>
            Can't find what you're looking for?{' '}
            <Link to={`/payload/${payloadId}/conversations/new`}>Start a conversation</Link>
          </Block>
        </Container>
      </>
    );
  }

  const files: File[] = sharedFiles[0].files;

  const primaryFiles = files.filter(file => file.type === 'primary');
  const secondaryFiles = files.filter(file => file.type === 'secondary');

  return (
    <>
      <AppBanner>
        <Title>Files</Title>
      </AppBanner>
      <Container>
        <Block extraPadding>
          {primaryFiles.map(file => (
            <PrimaryFile key={file.title} {...file} />
          ))}
        </Block>
        <Block extraPadding>
          <StyledWrapper>
            {secondaryFiles.map(file => (
              <SecondaryFile key={file.title} {...file} />
            ))}
          </StyledWrapper>
        </Block>
        <Segment>
          <Block extraPadding border={false}>
            Can't find what you're looking for?{' '}
            <Link to={`/payload/${payloadId}/conversations/new`}>Start a conversation</Link>
          </Block>
        </Segment>
      </Container>
    </>
  );
};
