import {
  Block,
  Column,
  Row,
  Segment,
  SegmentSpacing,
  Subheading,
  Text,
  theme,
} from '@blueorigin/blue-branding-kit';
import { Button } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { File } from './files';

const StyledColumn = styled.div`
  @media ${theme('Responsive.DesktopOnlyMedia')} {
    height: 100px;
  }
`;

const StyledImage = styled.img`
  @media ${theme('Responsive.DesktopOnlyMedia')} {
    height: 100%;
  }
`;

export const SecondaryFile = ({ image, title, description, url }: File) => (
  <Block border={false} extraPadding>
    <Row spacing={SegmentSpacing.MORE}>
      <Column>
        <StyledColumn>
          <StyledImage alt={`${title}`} src={require(`../assets/${image}@2x.png`)} />
        </StyledColumn>
      </Column>
      <Column style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Segment>
          <Subheading>{title}</Subheading>
        </Segment>
        <Segment spacing={SegmentSpacing.LESS}>
          <Text>{description}</Text>
        </Segment>
        <Segment spacing={SegmentSpacing.LESS}>
          <Button color="primary" variant="contained" onClick={() => window.open(url, '_blank')}>
            Download
          </Button>
        </Segment>
      </Column>
    </Row>
  </Block>
);
