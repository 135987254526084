import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';

export interface DashboardPayloadProps {
  payload: Payload;
}

export const DashboardGetStarted = (props: any) => (
  <Block extraPadding>
    <Segment>
      <Heading>Get Started</Heading>
    </Segment>
    <Segment>
      <Text>
        Your Payload has been booked into our system. The first step is for you to provide us with a
        Payload Data Package, which tells us everything we need to know to fly your payload on New
        Shepard.
      </Text>
    </Segment>
    <Segment>
      <Text>
        This website will help you work on and submit your data package to Blue Origin. We will then
        review it, and open comments with you for any questions we have or issues that we discover.
        As we guide you through this process, please log in to the Blue Origin payload site
        periodically to check your payload status, and whether there are any open action items that
        need to be resolved. We will also contact you by email with any notifications.
      </Text>
    </Segment>
  </Block>
);
