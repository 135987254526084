import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LoginCallback, OktaRoutes, useRequireAuth } from '@blueorigin/authentication-library';
import { AppLoading, Blue, ErrorDialog } from '@blueorigin/blue-branding-kit';
import { AnalyticsProvider } from '@blueorigin/customer-analytics/components/analytics-provider';
import { AnalyticsRouter } from '@blueorigin/customer-analytics/components/router/analytics-router';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'unfetch/polyfill';
import { appConfig } from './config';
import { Header } from './layout/header/header';
import { PayloadRoutes } from './payload/payload-routes';
import store from './redux/store';

export const link = new HttpLink({ uri: appConfig.apolloUri });

export const Wrapper = () => {
  const { loading, accessToken } = useRequireAuth();
  if (loading) {
    return <AppLoading />;
  }

  if (!accessToken) {
    return <ErrorDialog data="Error retrieving token">Error retrieving token</ErrorDialog>;
  }

  const setAuthorizationLink = setContext((request, previousContext) => {
    return {
      headers: {
        ...previousContext.headers,
        authorization: accessToken,
        'x-client-id': appConfig.okta.clientId,
      },
    };
  });

  const client = new ApolloClient({
    link: setAuthorizationLink.concat(link),
    cache: new InMemoryCache(),
  });

  return (
    <Blue>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Header />
          <Switch>
            <Route path="/payload/:payloadId" component={PayloadRoutes} />
            <Route path="/" component={PayloadRoutes} />
          </Switch>
        </Provider>
      </ApolloProvider>
    </Blue>
  );
};

export const App = () => (
  <AnalyticsProvider config={{ siteId: appConfig.telemetryId }}>
    <AnalyticsRouter>
      <BrowserRouter>
        <OktaRoutes
          authUri={appConfig.authUri}
          config={appConfig.okta}
          wrapper={Wrapper}
          callback={LoginCallback}
        />
      </BrowserRouter>
    </AnalyticsRouter>
  </AnalyticsProvider>
);
