import { combineReducers, compose, createStore } from 'redux';
import { NavigationState } from './actions/navigation.actions';
import { PayloadsState } from './actions/payloads.actions';
import { NavigationReducer } from './reducers/navigation.reducer';
import { PayloadsReducer } from './reducers/payloads.reducer';

export interface AppState {
  navigation: NavigationState;
  payloads: PayloadsState;
}

const composeEnhancers =
  (typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default createStore(
  combineReducers({
    navigation: NavigationReducer,
    payloads: PayloadsReducer,
  }),
  composeEnhancers(),
);
