import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import {
  Block,
  Device,
  LoadingSpinner,
  Logo,
  Menu,
  MenuCaret,
  MenuDropdown,
  MenuPrompt,
  Navigation,
  NavSelect,
  Primary,
  Secondary,
  Segment,
  SegmentSpacing,
  Space,
  Text,
} from '@blueorigin/blue-branding-kit';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Button, MenuItem } from '@material-ui/core';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationSection, NavigationState } from '../../redux/actions/navigation.actions';
import { PayloadsState } from '../../redux/actions/payloads.actions';
import { AppState } from '../../redux/store';
import { PayloadsService } from '../../services/payloads-service';
import { HeaderPayload } from './header-payload';
import { PayloadLabel } from './payload-label';

const PrintStyle = styled.div`
  @media print {
    display: none;
  }
`;

export interface HeaderProps {
  navigationState?: NavigationState;
  payloadsState?: PayloadsState;
  history: any;
  authState: AuthState;
  oktaAuth?: OktaAuth;
  client: ApolloClient<NormalizedCacheObject>;
}

export interface HeaderState {
  name: string;
}

export class HeaderComponent extends React.Component<HeaderProps, HeaderState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      name: null,
    };
  }

  public componentDidMount() {
    this.checkAuthentication();
  }

  public ensureNameLoaded = () => {
    if (!this.state.name) {
      this.checkAuthentication();
    }
  };

  public render() {
    const { navigationState, payloadsState } = this.props;
    const { name } = this.state;
    const label = <PayloadLabel {...{ navigationState, payloadsState }} />;
    const payloads = _.get(this.props, ['payloadsState', 'payloads']);

    // Don't show the header when there are zero payloads; there's a full-screen error page in this case
    if (payloads && payloads.length === 0) {
      return null;
    }

    return (
      <PrintStyle>
        <Navigation fixed transparent placeholder={false}>
          <ClickTracker category="Navigation" event="Logo">
            <Logo to="">Blue Origin</Logo>
          </ClickTracker>
          <Primary>
            <NavSelect label={label as any}>
              <MenuItem disabled>MY PAYLOADS</MenuItem>
              {_.map(payloads, p => (
                <ClickTracker key={p.id} category="Navigation" event="Select payload" name={p.name}>
                  <MenuItem onClick={() => this.onPayloadClick(p)}>{p.name}</MenuItem>
                </ClickTracker>
              ))}
            </NavSelect>
            {this.renderMenu()}
          </Primary>
          <Secondary>
            <Menu device={Device.Desktop}>
              <div className="loadPrompt" onClick={this.ensureNameLoaded}>
                <MenuPrompt>
                  <i className="fas fa-user-alt" />
                  <MenuCaret />
                </MenuPrompt>
              </div>
              <MenuDropdown right>
                <Block>
                  <div style={{ padding: `0 ${Space}` }}>
                    <Segment>
                      <Text>Signed in as</Text>
                    </Segment>
                    <Segment spacing={SegmentSpacing.TINY}>
                      <Text>
                        <strong>{name || <LoadingSpinner />}</strong>
                      </Text>
                    </Segment>
                  </div>
                </Block>
                <div style={{ padding: Space }}>
                  <ClickTracker category="Navigation" event="Signout">
                    <Button
                      onClick={this.logoutOnClick}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Sign out
                    </Button>
                  </ClickTracker>
                </div>
              </MenuDropdown>
            </Menu>
          </Secondary>
        </Navigation>
      </PrintStyle>
    );
  }

  public onPayloadClick = (payload: Payload) => {
    this.props.history.push(`/payload/${payload.id}`);
  };

  public renderMenu() {
    const { navigationState, payloadsState } = this.props;
    if (navigationState.section === NavigationSection.PAYLOAD) {
      return (
        <HeaderPayload
          payloadId={navigationState.payload || _.get(payloadsState, ['primary', 'id'])}
        />
      );
    }
    return null;
  }

  private checkAuthentication = async () => {
    const { isAuthenticated } = this.props.authState;

    if (isAuthenticated) {
      PayloadsService.ensureLoaded(this.props.payloadsState, this.props.client);

      // Get and store user info (particularly: their name)
      const userInfo = await this.props.oktaAuth.getUser();
      this.setState({
        name: userInfo.name,
      });
    }
  };

  private logoutOnClick = async () => {
    await this.props.oktaAuth.signOut();
    return;
  };
}

const mapStateToProps = (state: AppState, props: HeaderProps) => {
  return {
    ...props,
    navigationState: state.navigation,
    payloadsState: state.payloads,
  };
};

export const Header = withOktaAuth(
  withApollo(withRouter(connect(mapStateToProps, null)(HeaderComponent) as any) as any) as any,
);
