import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { Error } from '@blueorigin/blue-branding-kit';
import { ConversationType } from '@blueorigin/conversation-components';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { NavigationSection, setNavigation } from '../redux/actions/navigation.actions';
import { PayloadsState } from '../redux/actions/payloads.actions';
import { AppState } from '../redux/store';
import { PayloadsService } from '../services/payloads-service';
import { DashboardActionItems } from './dashboard-action-items';
import { DashboardBanner } from './dashboard-banner';
import { DashboardConversations } from './dashboard-conversations';
import { DashboardDataPackage } from './dashboard-data-package';
import { DashboardGetStarted } from './dashboard-get-started';
import { DashboardLoading } from './dashboard-loading';

export interface DashboardProps {
  match?: any;
  setNavigation?: (section: NavigationSection, payload: string) => void;
  payloadsState?: PayloadsState;
  client: ApolloClient<NormalizedCacheObject>;
}

export const ScrollMarkPositions = {
  GET_STARTED: 'GetStarted',
  ACTION_ITEMS: 'ActionItems',
  DATA_PACKAGE: 'DataPackage',
  CONVERSATIONS: 'Conversations',
  GET_HELP: 'GetHelp',
};

export const DashboardQuery = gql`
  query Q($payloadId: ID!, $payloadIdString: String!) {
    payload(id: $payloadId) {
      id
      name
      payloadDataPackageStatistics {
        approved
        open
        total
        inReview
      }
    }
    allConversations(payloadId: $payloadIdString) {
      id
      title
      status
      author
      actionDue
      type
    }
  }
`;

export class DashboardComponent extends React.Component<DashboardProps> {
  public componentDidMount() {
    this.props.setNavigation(NavigationSection.PAYLOAD, this.getPayloadId());
    PayloadsService.ensureLoaded(this.props.payloadsState, this.props.client);
  }

  public render() {
    const payloadId = this.getPayloadId();

    if (!payloadId) {
      return <DashboardLoading />;
    }

    const variables = { payloadId, payloadIdString: payloadId };

    return (
      <Query query={DashboardQuery} variables={variables} fetchPolicy="no-cache">
        {this.renderDashboard}
      </Query>
    );
  }

  public getPayloadId() {
    return _.get(
      this.props,
      ['match', 'params', 'payloadId'],
      _.get(this.props, ['payloadsState', 'primary', 'id'], null),
    );
  }

  public renderDashboard = ({ data, error, loading, refetch }: any) => {
    if (error || (!loading && !data.allConversations)) {
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load dashboard
        </Error>
      );
    }

    // Loading page while fetching payload info
    if (loading) {
      return <DashboardLoading />;
    }

    const { payload, allConversations } = data;

    const actionItems = _.sortBy(
      _.filter(allConversations, c => c.type === ConversationType.ACTION_ITEM),
      'actionDue',
    );
    const conversations = _.sortBy(
      _.filter(allConversations, c => c.type === ConversationType.CONVERSATION),
      'timestamp',
      'desc',
    );

    return (
      <React.Fragment>
        <DashboardBanner payload={payload} />
        <DashboardGetStarted />
        <DashboardActionItems actionItems={actionItems} payload={payload} />
        <DashboardDataPackage payload={payload} />
        <DashboardConversations conversations={conversations} payload={payload} />
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state: AppState, props: DashboardProps) => {
  return {
    ...props,
    payloadsState: state.payloads,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setNavigation: (section: NavigationSection, payload: string) =>
      dispatch(setNavigation({ section, payload })),
  };
};

export const Dashboard = withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardComponent as any) as any) as any,
);
