import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload, PDPStatus } from '@blueorigin/payload-components/models/payload';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react';
import { StatusChangeButton } from '../status-change-button';

export interface SignoffConfirmFormProps {
  payload: Payload;
  onClose: () => void;
  onNotReady: () => void;
}

export interface SignoffConfirmFormState {
  confirmCorrect: boolean;
  confirmInform: boolean;
  confirmFinal: boolean;
  confirmDeviations: boolean;
}

const CORRECT_TEXT =
  'I am responsible for the accuracy and completeness ' +
  'of the information included in this Data Package. I have reviewed all ' +
  'of the information, and to the best of my knowledge, the information ' +
  'is accurate and complete.';
const FINAL_TEXT =
  'This data package is ready to be finalized. I do not ' +
  'expect any of the information in this data package to change between now ' +
  'and when my payload is scheduled to fly.';
const INFORM_TEXT =
  'In the event any of the information changes after I sign ' +
  'this data package, I will promptly inform Blue Origin of ' +
  'those changes. Blue Origin will need to review and approve of any such ' +
  'changes and completing that process might ' +
  'affect the date on which my payload is scheduled to fly.';
const DEVIATIONS_TEXT =
  'Any deviations from the design represented in this data package and the actual payload may ' +
  'result in the payload being de-manifested from flight.';

export class SignoffConfirmForm extends React.Component<
  SignoffConfirmFormProps,
  SignoffConfirmFormState
> {
  constructor(props: SignoffConfirmFormProps) {
    super(props);
    this.state = {
      confirmCorrect: false,
      confirmInform: false,
      confirmFinal: false,
      confirmDeviations: false,
    };
  }

  public render() {
    const { payload, onNotReady, onClose } = this.props;
    const { confirmCorrect, confirmInform, confirmFinal, confirmDeviations } = this.state;
    return (
      <React.Fragment>
        <Block border={false}>
          <Segment>
            <Heading>Ready to sign</Heading>
          </Segment>
          <Segment>
            <Text>
              You are about to sign this Data Package. Prior to signing, please acknowledge each of
              the following:
            </Text>
          </Segment>
          <Segment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.confirmCorrect}
                  onChange={this.onCorrectChange}
                  value="confirmCorrect"
                />
              }
              label={CORRECT_TEXT}
            />
          </Segment>
          <Segment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.confirmFinal}
                  onChange={this.onFinalChanged}
                  value="confirmInform"
                />
              }
              label={FINAL_TEXT}
            />
          </Segment>
          <Segment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.confirmInform}
                  onChange={this.onInformChanged}
                  value="confirmInform"
                />
              }
              label={INFORM_TEXT}
            />
          </Segment>
          <Segment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.confirmDeviations}
                  onChange={this.onDeviationsChanged}
                  value="confirmInform"
                />
              }
              label={DEVIATIONS_TEXT}
            />
          </Segment>
          <Segment>
            <StatusChangeButton
              onUpdate={onClose}
              disabled={!(confirmCorrect && confirmInform && confirmFinal && confirmDeviations)}
              pdpStatus={PDPStatus.APPROVED}
              payload={payload}
            >
              Sign data package
            </StatusChangeButton>
          </Segment>
          <Segment>
            <Button color="primary" fullWidth onClick={onNotReady}>
              Not ready to sign?
            </Button>
          </Segment>
        </Block>
      </React.Fragment>
    );
  }

  public onCorrectChange = (event: any) => {
    this.setState({
      confirmCorrect: event.target.checked,
    });
  };

  public onInformChanged = (event: any) => {
    this.setState({
      confirmInform: event.target.checked,
    });
  };

  public onFinalChanged = (event: any) => {
    this.setState({
      confirmFinal: event.target.checked,
    });
  };

  public onDeviationsChanged = (event: any) => {
    this.setState({
      confirmDeviations: event.target.checked,
    });
  };
}
