import { ApolloClient, gql, NormalizedCacheObject, QueryResult } from '@apollo/client';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as _ from 'lodash';
import {
  errorPayloads,
  fulfillPayloads,
  PayloadsState,
  startPayloads,
} from '../redux/actions/payloads.actions';
import store from '../redux/store';

const GetPayloads = gql`
  {
    myPayloads {
      id
      name
    }
  }
`;

export class PayloadsService {
  public static ensureLoaded(
    payloadsState: PayloadsState,
    client: ApolloClient<NormalizedCacheObject>,
  ) {
    if (payloadsState && !payloadsState.initiated) {
      return PayloadsService.fetchPayloads(client);
    }
    return null;
  }

  public static fetchPayloads(
    client: ApolloClient<NormalizedCacheObject>,
  ): Promise<void | Payload[]> {
    store.dispatch(startPayloads());
    return client
      .query({
        query: GetPayloads,
        variables: {},
      })
      .then((result: QueryResult<Payload[]>) => {
        store.dispatch(
          fulfillPayloads({
            payloads: _.get(result, ['data', 'myPayloads']),
          }),
        );
        return result.data;
      })
      .catch((error: any) => {
        store.dispatch(errorPayloads({ error }));
        return;
      });
  }
}
