import { SecureRoute } from '@okta/okta-react';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ListActionItemsPage } from '../action-items/list-action-items';
import { ViewActionItemPage } from '../action-items/view-action-item';
import { ListConversationsPage } from '../conversations/list-conversations';
import { NewConversationPage } from '../conversations/new-conversation';
import { ViewConversationPage } from '../conversations/view-conversation';
import { Dashboard } from '../dashboard/dashboard';
import { NoPayloadsError } from '../errors/no-payloads-error';
import { FilesPageComponent } from '../files/files';
import { NavigationSection, setNavigation } from '../redux/actions/navigation.actions';
import { PayloadsState } from '../redux/actions/payloads.actions';
import { AppState } from '../redux/store';
import { ViewDataPackage } from './data-package/view-data-package';

export interface PayloadRoutesProps {
  match?: any;
  setNavigation?: any;
  payloadsState?: PayloadsState;
}

export class PayloadRoutesComponent extends React.Component<PayloadRoutesProps> {
  public render() {
    const payloadId = _.get(this.props, ['match', 'params', 'payloadId']);
    this.props.setNavigation(NavigationSection.PAYLOAD, payloadId);

    const { payloadsState } = this.props;
    if (payloadsState.payloads && payloadsState.payloads.length === 0) {
      return <NoPayloadsError />;
    }

    return (
      <Switch>
        <SecureRoute path="/payload/:payloadId/data-package" component={ViewDataPackage} />
        <SecureRoute path="/payload/:payloadId/conversations/new" component={NewConversationPage} />
        <SecureRoute
          path="/payload/:payloadId/conversations/new/:category"
          component={NewConversationPage}
        />
        <SecureRoute path="/payload/:payloadId/conversations" component={ListConversationsPage} />
        <SecureRoute path="/payload/:payloadId/action-items" component={ListActionItemsPage} />
        <SecureRoute path="/payload/:payloadId/files" component={FilesPageComponent} />
        <SecureRoute
          path="/payload/:payloadId/conversation/:id/:slug"
          component={ViewConversationPage}
        />
        <SecureRoute
          path="/payload/:payloadId/action-item/:id/:slug"
          component={ViewActionItemPage}
        />
        <SecureRoute path="/payload/:payloadId" component={Dashboard} />
        <SecureRoute path="" component={Dashboard} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: AppState, props: PayloadRoutesProps) => {
  return {
    ...props,
    payloadsState: state.payloads,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setNavigation: (section: NavigationSection, payload: string) =>
      dispatch(setNavigation({ section, payload })),
  };
};

export const PayloadRoutes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PayloadRoutesComponent as any) as any);
