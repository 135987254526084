import { Payload } from '@blueorigin/payload-components/models/payload';
import actionCreatorFactory from 'typescript-fsa';

export interface PayloadsState {
  payloads: Payload[] | null;
  primary: Payload;
  loading: boolean;
  initiated: boolean;
  error: any;
}

export const initialPayloadsState: PayloadsState = {
  payloads: null,
  primary: null,
  loading: false,
  initiated: false,
  error: null,
};

export interface FulfillPayloadsArgs {
  payloads: Payload[];
}

export interface ErrorPayloadsArgs {
  error: any;
}

const actionCreator = actionCreatorFactory();

export const startPayloads = actionCreator('PAYLOADS_LOADING_START');
export const fulfillPayloads = actionCreator<FulfillPayloadsArgs>('PAYLOADS_LOADING_FULFILL');
export const errorPayloads = actionCreator<ErrorPayloadsArgs>('PAYLOADS_LOADING_ERROR');
export const clearPayloads = actionCreator('CLEAR_PAYLOADS');
