import { Title } from '@blueorigin/blue-branding-kit';
import {
  Conversation,
  ConversationType,
  createSlug,
  NewConversation,
} from '@blueorigin/conversation-components';
import { History } from 'history';
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBanner } from '../layout/app-banner';

export interface NewConversationProps {
  history?: History;
  match?: any;
}

export class NewConversationPageComponent extends React.Component<NewConversationProps> {
  public render() {
    const { payloadId, category } = _.get(this.props, ['match', 'params'], {});
    return (
      <React.Fragment>
        <AppBanner>
          <Title>New Conversation</Title>
        </AppBanner>
        <NewConversation
          type={ConversationType.CONVERSATION}
          input={{ payloadId }}
          category={category}
          onAdd={this.onAdd}
          fileId={`${payloadId}/temp`}
        />
      </React.Fragment>
    );
  }

  public onAdd = (conversation: Conversation) => {
    const payloadId = _.get(this.props, ['match', 'params', 'payloadId']);
    this.props.history.push(
      `/payload/${payloadId}/conversation/${conversation.id}/${createSlug(conversation.title)}`,
    );
    return true;
  };
}

export const NewConversationPage = withRouter(NewConversationPageComponent as any) as any;
