import { gql, useMutation } from '@apollo/client';
import { ErrorDialog } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Button } from '@material-ui/core';
import * as React from 'react';

export const UpdatePayloadMutation = gql`
  mutation M($id: String!, $version: Int!, $payload: UpdatePayloadInput!) {
    updatePayload(id: $id, version: $version, payload: $payload) {
      id
      version
      name
      payloadDataPackage
      payloadDataPackageSchema
      payloadDataPackageStatistics {
        approved
        open
        total
        inReview
      }
      pdpStatus
    }
  }
`;

export interface StatusChangeButtonProps {
  disabled: boolean;
  payload: Payload;
  pdpStatus: string;
  onUpdate?: () => void;
  children: string;
}

export const StatusChangeButton = ({
  onUpdate,
  children,
  disabled,
  pdpStatus,
  payload,
}: StatusChangeButtonProps) => {
  const [mutation, { loading, error }] = useMutation(UpdatePayloadMutation, {
    onCompleted: onUpdate,
    onError: () => null,
  });

  const onChangeClick = () => {
    const { id, version } = payload;
    mutation({
      variables: {
        id,
        version,
        payload: {
          pdpStatus,
        },
      },
    });
  };

  if (error) {
    return <ErrorDialog data={error}>Could not update payload</ErrorDialog>;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      disabled={loading || disabled}
      onClick={onChangeClick}
    >
      {children}
    </Button>
  );
};
