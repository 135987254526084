import { NavItem, NavSeparator } from '@blueorigin/blue-branding-kit';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

export interface HeaderPayloadProps {
  payloadId: string;
}

interface NavLink {
  url: string;
  name: string;
  match: RegExp[];
}

const NAV: NavLink[] = [
  {
    url: '/payload/:id',
    name: 'Home',
    match: [/^$/, /^\/$/],
  },
  {
    url: '/payload/:id/action-items',
    name: 'Action Items',
    match: [/^\/?payload\/([a-zA-Z0-9-]+)\/action-item\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/?$/],
  },
  {
    url: '/payload/:id/data-package',
    name: 'Data Package',
    match: [],
  },
  {
    url: '/payload/:id/conversations',
    name: 'Conversations',
    match: [
      /^\/?payload\/([a-zA-Z0-9-]+)\/conversation\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)\/?$/,
      /^\/?payload\/([a-zA-Z0-9-]+)\/conversations\/new\/?([a-zA-Z0-9-]+)?$/,
    ],
  },
  {
    url: '/payload/:id/files',
    name: 'Files',
    match: [/^\/?payload\/([a-zA-Z0-9-]+)\/files\//],
  },
];

export class HeaderPayloadComponent extends React.Component<HeaderPayloadProps> {
  public render() {
    const id = this.props.payloadId;
    const currentUrl = window.location.pathname;
    const nav = _.map(NAV, (item: NavLink) => ({
      ...item,
      url: item.url.replace(':id', id),
    }));

    return (
      <React.Fragment>
        <NavSeparator />
        {nav.map(item => (
          <ClickTracker category="Navigation" event={item.name} key={item.url}>
            <NavItem
              to={item.url}
              active={
                item.url === currentUrl || Boolean(_.find(item.match, m => currentUrl.match(m)))
              }
            >
              {item.name}
            </NavItem>
          </ClickTracker>
        ))}
      </React.Fragment>
    );
  }
}

export const HeaderPayload = withRouter(HeaderPayloadComponent as any) as any;
