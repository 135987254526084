import {
  Block,
  List,
  LoadingHeading,
  LoadingListItem,
  LoadingListItemVariant,
  LoadingParagraph,
  LoadingTitle,
  Segment,
} from '@blueorigin/blue-branding-kit';
import * as _ from 'lodash';
import * as React from 'react';
import { AppBanner } from '../layout/app-banner';

export const FilesLoading = () => (
  <React.Fragment>
    <AppBanner>
      <Block border={false}>
        <Segment>
          <div style={{ maxWidth: '450px' }}>
            <LoadingTitle />
          </div>
        </Segment>
      </Block>
    </AppBanner>
    <Block extraPadding>
      <Segment>
        <LoadingHeading />
      </Segment>
      <Segment>
        <LoadingParagraph />
      </Segment>
    </Block>
    {_.map([1, 2], k => (
      <Block extraPadding key={k}>
        <Segment>
          <LoadingHeading />
        </Segment>
        <Segment>
          <List>
            <LoadingListItem variant={LoadingListItemVariant.TWO_ROW} />
            <LoadingListItem variant={LoadingListItemVariant.TWO_ROW} />
            <LoadingListItem variant={LoadingListItemVariant.TWO_ROW} />
            <LoadingListItem variant={LoadingListItemVariant.TWO_ROW} />
            <LoadingListItem variant={LoadingListItemVariant.TWO_ROW} />
          </List>
        </Segment>
      </Block>
    ))}
  </React.Fragment>
);
