import { Segment } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Button } from '@material-ui/core';
import * as React from 'react';
import { SignoffConfirmDialog } from './signoff-confirm-dialog';
import { SignoffNotReadyDialog } from './signoff-not-ready-dialog';

export interface SignoffButtonProps {
  payload: Payload;
}

export interface SignoffButtonState {
  confirmVisible: boolean;
  notReadyVisible: boolean;
}

export class Signoff extends React.Component<SignoffButtonProps, SignoffButtonState> {
  constructor(props: SignoffButtonProps) {
    super(props);
    this.state = {
      confirmVisible: false,
      notReadyVisible: false,
    };
  }

  public render() {
    const { payload } = this.props;
    return (
      <React.Fragment>
        <SignoffNotReadyDialog
          payloadId={payload.id}
          visible={this.state.notReadyVisible}
          onClose={this.onNotReadyFinished}
        />
        <SignoffConfirmDialog
          payload={payload}
          visible={this.state.confirmVisible}
          onClose={this.onConfirmFinished}
          onNotReady={this.onNotReady}
        />
        <Segment>
          <Button fullWidth variant="contained" color="primary" onClick={this.onSignOffClick}>
            Sign data package
          </Button>
        </Segment>
      </React.Fragment>
    );
  }

  public onSignOffClick = () => {
    this.setState({
      confirmVisible: true,
    });
  };

  public onNotReady = () => {
    this.setState({
      notReadyVisible: true,
      confirmVisible: false,
    });
  };

  public onConfirmFinished = () => {
    this.setState({
      confirmVisible: false,
    });
  };

  public onNotReadyFinished = () => {
    this.setState({
      notReadyVisible: false,
    });
  };
}
