import { LoadingSpinner, NavigationHeight, Title } from '@blueorigin/blue-branding-kit';
import { ViewActionItem } from '@blueorigin/conversation-components/components/action-items/view/view-action-item';
/* tslint:disable:max-line-length */
import { NewReplyPhrasing } from '@blueorigin/conversation-components/components/conversations/components/embed/new-reply';
import { History } from 'history';
/* tslint:enable:max-line-length */
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBanner } from '../layout/app-banner';

const ViewConversationPhrasing: NewReplyPhrasing = {
  addReply:
    'Need to respond or provide more information? Enter a reply below' +
    'to add to the conversation. We will get back to you with a response ' +
    'as soon as possible.',
  disabledReplyText:
    'This conversation has been marked as resolved, so no new replies ' +
    'can be made. You can start a new conversation if there is an ' +
    'additional topic to discuss, or item to bring to our attention.',
  resolveText: '',
};

export interface ViewConversationProps {
  history?: History;
  match?: any;
}

export class ViewActionItemPageComponent extends React.Component<ViewConversationProps> {
  public render() {
    const { payloadId, id } = _.get(this.props, ['match', 'params'], {});
    const query = { payloadId, id };
    return (
      <ViewActionItem
        query={query}
        phrasing={ViewConversationPhrasing}
        stickyTop={NavigationHeight}
        layout={(loading: boolean, actionItem: any, children: any) => {
          if (loading) {
            return <LoadingSpinner />;
          }
          return (
            <React.Fragment>
              <AppBanner>
                <Title>{actionItem.title}</Title>
              </AppBanner>
              {children}
            </React.Fragment>
          );
        }}
      />
    );
  }
}

export const ViewActionItemPage = withRouter(ViewActionItemPageComponent as any) as any;
