import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import { buildPreviousPayloadText } from './pdp-sidebar';
import { Signoff } from './signoff/signoff';

export interface SignBlockProps {
  payload: Payload;
}

export const SignBlock = (props: SignBlockProps) => {
  const { payload } = props;
  const isReflight = Boolean(payload.previousPayload);

  return (
    <Block>
      <Segment>
        <Heading>Ready to sign</Heading>
      </Segment>
      <Segment>{isReflight && buildPreviousPayloadText(payload.previousPayload)}</Segment>
      <Segment>
        <Text>Your data package has been approved and is ready for you to sign.</Text>
      </Segment>
      <Signoff payload={props.payload} />
    </Block>
  );
};
