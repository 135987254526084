import {
  Block,
  Progress,
  ProgressSegment,
  Segment,
  SegmentSpacing,
  Subheading,
  Text,
  Title,
  WhiteButton,
} from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { History } from 'history';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBanner } from '../layout/app-banner';

export interface DashboardBannerProps {
  payload: Payload;
  history?: History;
}

export class DashboardBannerComponent extends React.Component<DashboardBannerProps> {
  public render() {
    const { payload } = this.props;
    return (
      <AppBanner>
        <Block border={false}>
          <Segment>
            <Subheading>Manage your payload</Subheading>
          </Segment>
          <Segment>
            <Title>{payload.name}</Title>
          </Segment>
        </Block>
      </AppBanner>
    );
  }
}

export const DashboardBanner = withRouter(DashboardBannerComponent as any) as any;
