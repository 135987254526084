interface IAppConfig {
  apolloUri: string;
  authUri: string;
  okta: IOktaConfig;
  telemetryId: number;
}

interface IOktaConfig {
  clientId: string;
}

const oktaConfig: IOktaConfig = {
  clientId: '0oalhy9r0cBNrlPLH356',
};

let config: IAppConfig;
const validEnvironment = typeof APPLICATION !== 'undefined';
if (validEnvironment && 'prod' === APPLICATION.ENVIRONMENT) {
  config = {
    apolloUri: 'https://2p8vx9f24m.execute-api.us-gov-west-1.amazonaws.com/prod/graphql',
    authUri: 'https://auth.blueorigin.com',
    okta: oktaConfig,
    telemetryId: 4,
  };
} else if (validEnvironment && 'gamma' === APPLICATION.ENVIRONMENT) {
  config = {
    apolloUri: 'https://2p8vx9f24m.execute-api.us-gov-west-1.amazonaws.com/gamma/graphql',
    authUri: 'https://gamma-auth.blueorigin.com',
    okta: oktaConfig,
    telemetryId: 3,
  };
} else {
  config = {
    apolloUri: 'https://2p8vx9f24m.execute-api.us-gov-west-1.amazonaws.com/beta/graphql',
    authUri: 'https://beta-auth.blueorigin.com',
    okta: oktaConfig,
    telemetryId: 1,
  };
}

export const appConfig = config;
