import { Title } from '@blueorigin/blue-branding-kit';
import { ListActionItems } from '@blueorigin/conversation-components/components/action-items/list/list-action-items';
import { ActionItem } from '@blueorigin/conversation-components/models/conversations';
import { createSlug } from '@blueorigin/conversation-components/utils/slugs';
import { History } from 'history';
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBanner } from '../layout/app-banner';

export interface ListConversationProps {
  history?: History;
  match?: any;
}

export class ListActionItemsPageComponent extends React.Component<ListConversationProps> {
  public render() {
    const payloadId = this.getPayloadId();
    const query = { payloadId };
    const { onView } = this;
    return (
      <React.Fragment>
        <AppBanner>
          <Title>Action Items</Title>
        </AppBanner>
        <ListActionItems {...{ query, onView }} />
      </React.Fragment>
    );
  }

  public onView = (actionItem: ActionItem) => {
    const payloadId = this.getPayloadId();
    this.props.history.push(
      `/payload/${payloadId}/action-item/${actionItem.id}/${createSlug(actionItem.title)}`,
    );
  };

  public getPayloadId() {
    return _.get(this.props, ['match', 'params', 'payloadId']);
  }
}

export const ListActionItemsPage = withRouter(ListActionItemsPageComponent as any);
