import {
  Block,
  Heading,
  Link,
  List,
  Segment,
  SegmentSpacing,
  ZeroItems,
} from '@blueorigin/blue-branding-kit';
/* tslint:disable:max-line-length */
import { ActionItemEntry } from '@blueorigin/conversation-components/components/action-items/components/action-item-entry';
import { ActionItem } from '@blueorigin/conversation-components/models/conversations';
import { createSlug } from '@blueorigin/conversation-components/utils/slugs';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { History } from 'history';
/* tslint:enable:max-line-length */
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

export interface DashboardActionItemsProps {
  payload: Payload;
  actionItems: ActionItem[];
  history?: History;
  match?: any;
}

export class DashboardActionItemsComponent extends React.Component<DashboardActionItemsProps> {
  public render() {
    const { actionItems, payload } = this.props;
    const { id } = payload;
    const slice = actionItems.slice(0, 5);
    return (
      <Block extraPadding>
        <Segment>
          <Heading>Action Items</Heading>
        </Segment>
        <Segment spacing={SegmentSpacing.LESS}>
          <List>
            {_.map(slice, (item: ActionItem) => (
              <ClickTracker
                key={item.id}
                category="Dashboard"
                event="View action item"
                name={item.title}
              >
                <ActionItemEntry key={item.id} actionItem={item} onClick={this.onActionItemClick} />
              </ClickTracker>
            ))}
          </List>
        </Segment>
        {actionItems.length > slice.length && (
          <Segment>
            <ClickTracker category="Dashboard" event="View all action items">
              <Link to={`/payload/${id}/action-items`}>View all action items</Link>
            </ClickTracker>
          </Segment>
        )}
        {actionItems.length === 0 && <ZeroItems pluralNoun="action items" />}
      </Block>
    );
  }

  public onActionItemClick = (actionItem: ActionItem) => {
    this.props.history.push(
      `/payload/${this.props.payload.id}/action-item/${actionItem.id}/${createSlug(
        actionItem.title,
      )}`,
    );
  };
}

export const DashboardActionItems = withRouter(DashboardActionItemsComponent as any) as any;
