import { Column, Link, MobileOnlyMedia, Sticky, Text } from '@blueorigin/blue-branding-kit';
import { Payload, PDPStatus } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import styled from 'styled-components';
import { ApprovedBlock } from './approved-block';
import { DraftBlock } from './draft-block';
import { ProgressBlock } from './progress-block';
import { ReviewBlock } from './review-block';
import { SignBlock } from './sign-block';

const SidebarWrap = styled.div`
  padding-right: 30px;
  @media ${MobileOnlyMedia} {
    padding-right: 0px;
  }
  @media print {
    display: none;
  }
`;

/**
 * Returns relfight paragraph with previous payload's name and link to its dashboard
 *
 * @param {string} name previous payload name
 * @param {string} id previous payload id
 * @return React component
 */
export const buildPreviousPayloadText = (previousPayload: { name: string; id: string }) => {
  return (
    <Text>
      This is a reflight payload of{' '}
      <Link to={`/payload/${previousPayload.id}`}>
        <strong>{previousPayload.name}</strong>
      </Link>
      . Your payload data package has been automatically filled with data from the previous flight.
    </Text>
  );
};

export interface PDPSidebarProps {
  payload: Payload;
  content: any;
}

export class PDPSidebar extends React.Component<PDPSidebarProps> {
  public render() {
    const { content } = this.props;
    return (
      <Column width="300px">
        <Sticky>
          <SidebarWrap>
            {this.renderBlock()}
            {content}
          </SidebarWrap>
        </Sticky>
      </Column>
    );
  }

  public renderBlock() {
    const { payload } = this.props;
    const { pdpStatus } = payload;
    if (pdpStatus === PDPStatus.INITIAL) {
      return <ReviewBlock payload={payload} />;
    } else if (pdpStatus === PDPStatus.IN_PROGRESS) {
      return <ProgressBlock payload={payload} />;
    } else if (pdpStatus === PDPStatus.READY_TO_SIGN) {
      return <SignBlock payload={payload} />;
    } else if (pdpStatus === PDPStatus.APPROVED) {
      return <ApprovedBlock payload={payload} />;
    } else {
      return <DraftBlock payload={payload} />;
    }
  }
}
