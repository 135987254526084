import {
  Column,
  Row,
  Segment,
  SegmentSpacing,
  Subheading,
  SubtextHeading,
  Text,
  theme,
} from '@blueorigin/blue-branding-kit';
import { Button } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { File } from './files';

const StyledColumn = styled.div`
  @media ${theme('Responsive.DesktopOnlyMedia')} {
    height: 210px;
  }
`;

const StyledImage = styled.img`
  @media ${theme('Responsive.DesktopOnlyMedia')} {
    height: 100%;
  }
`;

export const PrimaryFile = ({ image, title, subtitle, description, url }: File) => (
  <Row spacing={SegmentSpacing.MORE}>
    <Column>
      <StyledColumn>
        <StyledImage alt="payload-users-guide" src={require(`../assets/${image}@2x.png`)} />
      </StyledColumn>
    </Column>
    <Column style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Segment>
        <Segment>
          <Subheading>{subtitle}</Subheading>
        </Segment>
        <Segment spacing={SegmentSpacing.TINY}>
          <SubtextHeading>{title}</SubtextHeading>
        </Segment>
      </Segment>
      <Segment>
        <Text>{description}</Text>
      </Segment>
      <Segment>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => window.open(url, '_blank')}
        >
          Download
        </Button>
      </Segment>
    </Column>
  </Row>
);
