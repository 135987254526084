import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import { buildPreviousPayloadText } from './pdp-sidebar';

export interface ProgressBlockProps {
  payload: Payload;
}

export const ProgressBlock = (props: ProgressBlockProps) => {
  const { payload } = props;
  const isReflight = Boolean(payload.previousPayload);

  return (
    <Block>
      <Segment>
        <Heading>Edit</Heading>
      </Segment>
      <Segment>{isReflight && buildPreviousPayloadText(payload.previousPayload)}</Segment>
      <Segment>
        <Text>
          Please continue work on your data package. Submit individual answers for approval as you
          progress.
        </Text>
      </Segment>
    </Block>
  );
};
