import {
  BackdropAlignment,
  Banner,
  Block,
  BlueLoadingBackground,
  NavigationPlaceholder,
  Space,
} from '@blueorigin/blue-branding-kit';
import * as React from 'react';
const Backdrop = require('../assets/space.png');

export class AppBanner extends React.Component<{}> {
  public render() {
    return (
      <Banner image={Backdrop} align={BackdropAlignment.TOP}>
        <BlueLoadingBackground>
          <NavigationPlaceholder />
          <Block border={false}>{this.props.children}</Block>
          <div style={{ height: Space }} />
        </BlueLoadingBackground>
      </Banner>
    );
  }
}
