import { Blue } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Dialog } from '@material-ui/core';
import * as React from 'react';
import { SignoffConfirmForm } from './sign-confirm-form';

export interface SignoffConfirmDialogProps {
  payload: Payload;
  visible: boolean;
  onClose: () => void;
  onNotReady: () => void;
}

export interface SignoffConfirmDialogState {
  confirmCorrect: boolean;
  confirmInform: boolean;
  confirmFinal: boolean;
  confirmDeviations: boolean;
}

export class SignoffConfirmDialog extends React.Component<
  SignoffConfirmDialogProps,
  SignoffConfirmDialogState
> {
  public render() {
    const { onClose } = this.props;
    return (
      <Dialog
        open={this.props.visible}
        scroll="body"
        onClose={onClose}
        maxWidth="md"
        fullWidth={true}
      >
        <div>
          <Blue>
            <SignoffConfirmForm {...this.props} />
          </Blue>
        </div>
      </Dialog>
    );
  }
}
