import { Block, Heading, Link, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import { buildPreviousPayloadText } from './pdp-sidebar';

export interface DraftBlockProps {
  payload: Payload;
}

export const ApprovedBlock = (props: DraftBlockProps) => {
  const { payload } = props;
  const isReflight = Boolean(payload.previousPayload);

  return (
    <Block>
      <Segment>
        <Heading>Approved</Heading>
      </Segment>
      <Segment>{isReflight && buildPreviousPayloadText(payload.previousPayload)}</Segment>
      <Segment>
        <Text>
          Your data package has been approved. If you need to make any changes, click below to start
          a conversation, but note that your payload's flight date may be affected.
        </Text>
      </Segment>
      <Segment>
        <Link to={`/${props.payload.id}/conversations/new`}>Start a conversation</Link>
      </Segment>
    </Block>
  );
};
