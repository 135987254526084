import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import { buildPreviousPayloadText } from './pdp-sidebar';

export interface ReviewBlockProps {
  payload: Payload;
}

export const ReviewBlock = (props: ReviewBlockProps) => {
  const { payload } = props;
  const isReflight = Boolean(payload.previousPayload);

  return (
    <Block>
      <Segment>
        <Heading>In review</Heading>
      </Segment>
      <Segment>{isReflight && buildPreviousPayloadText(payload.previousPayload)}</Segment>
      <Segment>
        <Text>
          Thank you for submitting your draft. You will hear from Blue Origin soon, in the meantime
          you can continue to complete open fields.
        </Text>
      </Segment>
    </Block>
  );
};
