import { Block, Column, Heading, Row, Segment } from '@blueorigin/blue-branding-kit';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { PDPProgress } from '@blueorigin/payload-components/components/pdp/progress/pdp-progress';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Button } from '@material-ui/core';
import { History } from 'history';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

export interface DashboardPayloadProps {
  payload: Payload;
  history?: History;
}

export class DashboardDataPackageComponent extends React.Component<DashboardPayloadProps> {
  public render() {
    const { payloadDataPackageStatistics } = this.props.payload;
    return (
      <Block extraPadding>
        <Segment>
          <Heading>Data Package</Heading>
        </Segment>
        <PDPProgress stats={payloadDataPackageStatistics} />
        <Segment>
          <Row>
            <Column>
              <ClickTracker category="Dashboard" event="Edit data package">
                <Button color="primary" variant="contained" onClick={this.onViewPDP}>
                  Edit Data Package
                </Button>
              </ClickTracker>
            </Column>
          </Row>
        </Segment>
      </Block>
    );
  }

  public onViewPDP = () => {
    const { id } = this.props.payload;
    this.props.history.push(`/payload/${id}/data-package`);
  };
}

export const DashboardDataPackage = withRouter(DashboardDataPackageComponent as any) as any;
