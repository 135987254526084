import { Title } from '@blueorigin/blue-branding-kit';
/* tslint:disable:max-line-length */
import { ListConversations } from '@blueorigin/conversation-components/components/conversations/list/list-conversations';
import { Conversation } from '@blueorigin/conversation-components/models/conversations';
import { createSlug } from '@blueorigin/conversation-components/utils/slugs';
import { History } from 'history';
/* tslint:enable:max-line-length */
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppBanner } from '../layout/app-banner';

export interface ListConversationProps {
  history?: History;
  match?: any;
}

export class ListConversationsPageComponent extends React.Component<ListConversationProps> {
  public render() {
    const payloadId = this.getPayloadId();
    const query = { payloadId };
    const { onNewConversation, onView } = this;
    return (
      <React.Fragment>
        <AppBanner>
          <Title>Conversations</Title>
        </AppBanner>
        <ListConversations {...{ query, onNewConversation, onView }} />
      </React.Fragment>
    );
  }

  public onNewConversation = () => {
    const payloadId = this.getPayloadId();
    this.props.history.push(`/payload/${payloadId}/conversations/new`);
  };

  public onView = (conversation: Conversation) => {
    const payloadId = this.getPayloadId();
    this.props.history.push(
      `/payload/${payloadId}/conversation/${conversation.id}/${createSlug(conversation.title)}`,
    );
  };

  public getPayloadId() {
    return _.get(this.props, ['match', 'params', 'payloadId']);
  }
}

export const ListConversationsPage = withRouter(ListConversationsPageComponent as any) as any;
