import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Actions from '../actions/navigation.actions';

export const NavigationReducer = reducerWithInitialState(Actions.initialNavigationState)
  .case(Actions.setNavigation, (state, { section, payload }) => {
    return {
      ...state,
      section,
      payload,
    };
  })
  .case(Actions.clearNavigation, state => ({
    ...state,
    section: null,
    payload: null,
  }))
  .build();
