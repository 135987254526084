import * as _ from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Actions from '../actions/payloads.actions';

export const PayloadsReducer = reducerWithInitialState(Actions.initialPayloadsState)
  .case(Actions.startPayloads, state => {
    return {
      ...state,
      initiated: true,
      loading: true,
      error: null,
    };
  })
  .case(Actions.fulfillPayloads, (state, { payloads }) => {
    return {
      ...state,
      payloads,
      primary: _.first(payloads),
      loading: false,
      error: null,
    };
  })
  .case(Actions.errorPayloads, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  })
  .case(Actions.clearPayloads, state => ({
    ...state,
    ...Actions.initialPayloadsState,
  }))
  .build();
