import { Block, Heading, Segment, Text } from '@blueorigin/blue-branding-kit';
import { Payload, PDPStatus } from '@blueorigin/payload-components/models/payload';
import * as React from 'react';
import { buildPreviousPayloadText } from './pdp-sidebar';
import { StatusChangeButton } from './status-change-button';

export interface DraftBlockProps {
  payload: Payload;
}

export const DraftBlock = (props: DraftBlockProps) => {
  const { payload } = props;
  const isReflight = Boolean(payload.previousPayload);

  return (
    <Block>
      <Segment>
        <Heading>Start</Heading>
      </Segment>
      <Segment>{isReflight && buildPreviousPayloadText(payload.previousPayload)}</Segment>
      <Segment>
        <Text>
          Please begin drafting your data package. You may submit an initial draft for review after
          you have completed the required fields that are denoted with an asterisk.
        </Text>
      </Segment>
      <Segment>
        <StatusChangeButton disabled={false} pdpStatus={PDPStatus.INITIAL} payload={props.payload}>
          Submit Initial Draft
        </StatusChangeButton>
      </Segment>
    </Block>
  );
};
