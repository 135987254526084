import {
  Block,
  Heading,
  Link,
  List,
  Segment,
  SegmentSpacing,
  Subheading,
  Text,
  ZeroItems,
} from '@blueorigin/blue-branding-kit';
import { Conversation, ConversationEntry, createSlug } from '@blueorigin/conversation-components';
import { ClickTracker } from '@blueorigin/customer-analytics/components/click-tracker';
import { Payload } from '@blueorigin/payload-components/models/payload';
import { Button } from '@material-ui/core';
import { History } from 'history';
import * as _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

export interface DashboardPayloadProps {
  conversations: Conversation[];
  payload: Payload;
  history?: History;
}

export class DashboardConversationsComponent extends React.Component<DashboardPayloadProps> {
  public render() {
    const { conversations, payload } = this.props;
    const { id } = payload;
    const slice = conversations.slice(0, 5);
    return (
      <Block extraPadding>
        <Segment>
          <Heading>Conversations</Heading>
        </Segment>
        <Segment spacing={SegmentSpacing.LESS}>
          <List>
            {_.map(slice, (item: Conversation) => (
              <ClickTracker
                key={item.id}
                category="Dashboard"
                event="View conversation item"
                name={item.title}
              >
                <ConversationEntry
                  key={item.id}
                  conversation={item}
                  onClick={this.onConversationClick}
                />
              </ClickTracker>
            ))}
          </List>
        </Segment>
        {conversations.length > slice.length && (
          <Segment spacing={SegmentSpacing.LESS}>
            <ClickTracker category="Dashboard" event="View all conversations">
              <Link to={`/payload/${id}/conversations`}>View all conversations</Link>
            </ClickTracker>
          </Segment>
        )}
        {conversations.length === 0 && <ZeroItems pluralNoun="conversations" />}
        <Segment>
          <Subheading>Contact &amp; Help</Subheading>
        </Segment>
        <Segment>
          <Text>
            Need to contact us with questions about your payload, or need help using this website?
            Start a conversation -- we'll get back to you as soon as possible.
          </Text>
        </Segment>
        <Segment>
          <ClickTracker category="Dashboard" event="Start conversation">
            <Button color="primary" variant="contained" onClick={this.onStartConversation}>
              Start conversation
            </Button>
          </ClickTracker>
        </Segment>
      </Block>
    );
  }

  public onConversationClick = (conversation: Conversation) => {
    this.props.history.push(
      `/payload/${this.props.payload.id}/conversation/${conversation.id}/${createSlug(
        conversation.title,
      )}`,
    );
  };

  public onStartConversation = () => {
    const { id } = this.props.payload;
    this.props.history.push(`/payload/${id}/conversations/new`);
  };
}

export const DashboardConversations = withRouter(DashboardConversationsComponent as any) as any;
