import actionCreatorFactory from 'typescript-fsa';

export enum NavigationSection {
  PAYLOAD,
  ACCOUNT,
}

export interface NavigationState {
  section: NavigationSection | null;
  payload: string | null;
}

export const initialNavigationState: NavigationState = {
  section: null,
  payload: null,
};

const actionCreator = actionCreatorFactory();

export const setNavigation = actionCreator<NavigationState>('SET_NAVIGATION');
export const clearNavigation = actionCreator('CLEAR_NAVIGATION');
