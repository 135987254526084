import { Auth, Heading, PrimaryBlue, Segment } from '@blueorigin/blue-branding-kit';
import { AnalyticsContext } from '@blueorigin/customer-analytics/components/analytics-provider';
import * as React from 'react';

export class NoPayloadsError extends React.Component {
  public static contextType = AnalyticsContext;

  public componentDidMount = () => {
    this.context.analyticsService.event('Errors', 'No Payloads');
  };

  public render() {
    return (
      <Auth color={PrimaryBlue}>
        <Segment>
          <Heading>No Payloads</Heading>
        </Segment>
        <Segment>You don't have any payloads assigned to your account.</Segment>
      </Auth>
    );
  }
}
